<template>
  <div>
    <router-link
      :to="{ name: 'PropertyVisitTimeSlots' }"
      class="link tg-mobile-link-small"
    >
      <i class="far fa-chevron-left mr-2" />
      {{ $t('choose_visit_time') }}
    </router-link>
    <h3 class="my-5 tg-mobile-header-3 lg:tg-desktop-header-3">
      {{ $t('suitable_time_not_found') }}?
    </h3>
    <p class="my-5 tg-mobile-body lg:tg-desktop-body">
      {{ $t('make_personal_appointment') }}
    </p>
    <FormulateForm
      #default="{ isLoading, hasErrors }"
      v-model="visitRequest"
      name="createVisitRequest"
      @submit="submitQuery"
    >
      <FormulateInput
        type="text"
        name="first_name"
        :label="$t('first_name')"
        validation="required:trim"
        :show-required-label="true"
      />
      <FormulateInput
        type="text"
        name="last_name"
        :label="$t('last_name')"
        validation="required:trim"
        :show-required-label="true"
      />
      <FormulateInput
        v-model.trim="visitRequest.email"
        type="email"
        name="email"
        :label="$t('email_label')"
        validation="bail|required|email"
        :show-required-label="true"
      />
      <FormulateInput
        type="dial_code_tel"
        name="phone_number"
        :label="$t('mobile')"
        validation="bail|required|phone"
        :show-required-label="true"
      />
      <FormulateInput
        type="textarea"
        name="message"
        :label="$t('what_is_suitable_time')"
        validation="required:trim"
        :show-required-label="true"
      />
      <FormulateInput
        type="submit"
        :disabled="isLoading || hasErrors"
        :input-class="['w-full lg:w-auto']"
        outer-class="my-7.5"
      >
        <i
          :class="[
            'far mr-2',
            isLoading ? 'fa-spinner-third animate-spin' : 'fa-paper-plane'
          ]"
        />
        {{ $t('send_proposal') }}
      </FormulateInput>
    </FormulateForm>
  </div>
</template>

<script>
import { createVisitRequest } from '@/services/calendarService';

export default {
  name: 'PropertyVisitGetInTouch',
  data() {
    return {
      propertyId: this.$route.params.propertyId,
      visitRequest: {}
    };
  },
  methods: {
    async submitQuery(data) {
      try {
        const response = await createVisitRequest(this.propertyId, data);
        this.$store.dispatch('snackbar/show', {
          type: 'success',
          messageBold: data.first_name + ',',
          message: ' ' + this.$t('message_received'),
          route: { name: 'PropertyVisitTimeSlots' }
        });
        return response;
      } catch (error) {
        this.$formulate.handle(error, 'createVisitRequest');
      }
    }
  }
};
</script>
